.welcome-modal {
    position: fixed; /* Fixed position to cover the entire viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 10vh;
    background: linear-gradient(180deg, rgba(255,255,255,1) 58%, rgba(255,255,255,0.7497199563419117) 90%, rgba(255,255,255,0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* High z-index to ensure it appears on top */
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    pointer-events: none;
  }
  
  .modal-content {
    text-align: center;
    padding: 20px;
    padding-bottom: 35vh;
  }
  
  .modal-content h1 {
    font-size: 3em;
    margin-bottom: 20px;
  }
  
  .modal-content p {
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  