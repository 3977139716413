.card {
    border: 1px solid gray;
    margin: 4px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10vh;
    cursor: pointer;
}

.card p {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0 4px;
}

.card img {
    width: 100%;
    height: 10vh;
    object-fit: cover; 
    object-position: center;
}

.selected {
    border-width: 2px;
    background-color: rgb(255, 191, 95);
}

@keyframes pop-in {
    from {
      transform: scale(0.5);
      opacity: 0;
      visibility: visible;
    }
    to {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
  }
  
  .pop-in {
    visibility: hidden; /* Hide initially */
    animation: pop-in 0.3s ease forwards;
  }
  
  .hidden {
    visibility: hidden;
  }