.header {
    background-color: black;
    color: white;
    padding: 1vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.header-title h1 {
    margin: 1vh;
    font-size: 24px;
}

.header-right {
    display: flex;
    align-items: center;
}

.header-right p {
    margin: 0 10px 0 0;
}

.logout-button {
    background-color: white;
    color: black;
    border: none;
    padding: 1vh;
    border-radius: 1vh;
    cursor: pointer;
}

.logout-button:hover {
    background-color: grey;
}

.user-icon::before {
    content: '👤'; /* Unicode character for envelope icon */
    margin-right: 8px;
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
        justify-content: center;
    }
}
  