.close-button {
    position: absolute;
    top: 3vh;
    right: 3vh;
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    transition: color 0.3s ease;
  }
  
  .close-button:hover {
    color: #ff0000;
  }
  
  .close-button:focus {
    outline: none;
  }  