.bucket-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:1vh;
    background-color: #00000000;
    border-radius: 1vh;
    padding: 0 0 1vh 0;
    margin: 1vh 1vh 0 1vh;
}

.buckets {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buckets-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1vh;
    align-items: center;
    margin-left: 3vw;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pagination-button {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 4px;
    background-color: transparent;
    color: #333; /* Dark gray text */
    font-size: 5rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin: 0 0.5vh;
  }
  
  .pagination-button:disabled {
    border-color: #eee;
    background-color: transparent;    color: #aaa; /* Light gray text for disabled */
    cursor: not-allowed;
  }
  
  .pagination-button:hover:not(:disabled) {
    background-color: transparent;    color: #fff; /* White text on hover */
    border: none;
  }
  
  .pagination-info {
    font-size: 1rem;
    color: #c4c4c4; 
  }
  