.footer {
    background-color: black;
    color: white;
    padding: 1.5vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .footer-text {
    display:flex;
    flex-direction: column;
    width: 100%;
  }
  
  .footer-text p {
    margin: 2px 0;
  }
  
  .envelope-icon::before {
    content: '📧'; /* Unicode character for envelope icon */
    margin-right: 8px;
  }