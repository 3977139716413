.display-panel-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.annotation {
    background-color: black;
    border-radius: 100vh;
    color: white;
    font-size: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5vh;
}