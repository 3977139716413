html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.virtual-tour {
  position: relative;
  height: 100%;
}
  