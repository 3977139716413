.display-panel-container {
    position: relative;
    width: 100%;
    height: 100%;
}
  
.image-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}