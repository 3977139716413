.resource-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.tab-button {
    padding: 0.75rem 0.75rem;
    border: none;
    border-radius: 1vh 1vh 0 0;
    background-color: #868686; /* White background */
    color: #333; /* Dark gray text */
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .tab-button:hover:not(:disabled) {
    background-color: #333; /* Dark gray background on hover */
    color: #fff; /* White text on hover */
  }
  
  .tab-button:hover {
    background-color: #f0f0f0; /* Slightly darker background on hover */
  }
  
  .tab-button.active {
    background-color: #ffffff; /* Dark gray background for active tab */
    color: black; /* White text for active tab */
  }