.source-list-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: rgb(255, 255, 255);

  border-radius: 0 1vh 1vh 0;
  height: 80vh;
  width: 100%;
}

.source-list {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  padding: 5vh 5vh 2vh 5vh;
}

.query-results {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(255, 255, 255);
}

.search-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  background-color: rgb(255, 255, 255);
}

.search-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input {
  padding: 0.25rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
  margin-right: 0.5rem;
  width: 30vw;
}

.search-button {
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #555;
}

.search-select {
  padding: 0.25rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
  width: 10vw;
}

.create-new-button {
  height: 7.5vh;
  width: 7.5vh;
  position: absolute;
  top: 40%;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgb(19, 19, 19);
  color: white;

  border-radius: 1vh 0 0 1vh;

  text-align: center;
  font-size: 1.2rem;

  cursor: pointer;
}

.create-new-button:hover {
  background-color: rgb(61, 61, 61);
}

.popup-create-model {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0 1vh 1vh 0;
}

.action-buttons-wrapper {
  position: absolute;
  bottom: 1vh;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.delete-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: 0.5vw;
  margin-right: 1vw;
}

.delete-button {
  padding: 0.25rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 0 0.5rem;
}

.delete-button:disabled {
  border-color: #eee;
  background-color: #f9f9f9;
  color: #aaa;
  cursor: not-allowed;
}

.delete-button:hover:not(:disabled) {
  background-color: #333;
  color: #fff;
}

.pagination-controls-left {
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vh;
}

.pagination-button-left {
  padding: 0.25rem 0.5rem;
}