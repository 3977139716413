.info-popup-container {
    background-color: black;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: scroll;

    margin: 3vh 10vw;
    padding: 3vh 10vh;
    border-radius: 1vh;

    container-type: inline-size;
}

.info-popup {
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info-popup img {
    width: 90%;
}

.info-popup .model-preview {
    height: 20vw;
    width: 40vw;
}

.content-description {
    text-align: left;
}

@media (max-width: 1024px) {
    .info-popup-container {
        margin: 3vh;
        padding: 5vh;
    }

    .info-popup .model-preview {
        height: 30vh;
        width: 60vw;
    }

    .content-description {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .info-popup-container {
        margin: 1vh;
        padding: 5vh;
    }

    .info-popup .model-preview {
        height: 30vh;
        width: 60vw;
    }

    .content-description {
        width: 100%;
    }
}