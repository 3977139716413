.target-bucket {
    display: flex;
    flex-direction: column;
}

.bucket-title {
    width: 50%;
    padding: 0.25rem;
    font-weight: bold;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
    border-radius: 10vh;
    margin: auto;
    margin-bottom: 1vh;
}