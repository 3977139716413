.info-card {
    color: white;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     /* High z-index to ensure it appears on top */
    overflow: scroll;
    padding: 2vh;
    margin: 1vh 1vw;
  }

  .dev-info-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    color: rgb(0, 0, 0);
    padding: 0 3vh 2vh 3vh;
    gap: 1vh;
    background-color: rgb(255, 255, 255);
    width: 90%;
  }

  .dev-info-card h1 {
    font-size: 1.5rem;
    width: 100%;
  }

  .dev-info-card img {
    max-height: 20vh;
  }

  .dev-info-card .image-and-description {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    gap: 2vh;
  }

  .dev-info-card .credits {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  