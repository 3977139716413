.popup-form {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vh;
    border-radius: 0 1vh 1vh 0;
    height: 100%;
    width: 50%;
    animation: slide-in 0.75s forwards;
}

@keyframes slide-in {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

.popup-form h2 {
    width: 100%;
    text-align: center;
}

.popup-form label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 100%;
}

.popup-form input[type="text"] {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
}

.popup-form button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.popup-form img {
    max-height: 20vh;
}

.popup-form button:hover {
    background-color: #0056b3;
}

.popup-form button:disabled {
    background-color: #bdbdbd;
    color: #e4e4e4
}

.popup-form .closeButton {
    background-color: #ccc;
    margin-top: 10px;
}

.popup-form .closeButton:hover {
    background-color: #999;
}

.preview-img {
    width: 10vw;

}

.square-canvas-container {
    width: 10vw;
    height: 10vw;
}

.snapshot-container {
    display: flex;
    flex-direction: row;
    gap: 0.2vw;
}

.model-upload-form {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    align-items: center;
    justify-content: center;
}

.popup-form .cancel-button {
    background-color: rgb(235, 47, 0);
}

.popup-form .cancel-button:hover {
    background-color: rgb(136, 34, 9);
}


.confirm-cancel-buttons {
    width: 100%;
    display: flex;
    gap: 0.5vw;
    align-items: center;
    justify-content: center;
}